import React, { useState, useRef } from 'react';
import { Carousel, Row, Col, Tooltip } from 'antd';
import Link from 'next/link';
import CompanyImage from './Image';
import CarouselPagination from 'components/CarouselPagination';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import SeeMoreCard from 'components/SeeMoreCard';
import chunk from 'lib/chunkArray';

interface Props {
    companies: Company[];
    imagesPerSlide?: number;
    autoplay?: boolean;
    seeMore?: boolean;
}

const CompanyCarousel = ({
    companies,
    imagesPerSlide = 12,
    autoplay = true,
    seeMore = false
}: Props): JSX.Element => {
    const slider = useRef(null);
    const [index, setIndex] = useState(0);

    const goNext = () => {
        slider.current.next();
    };

    const goPrevious = () => {
        slider.current.prev();
    };

    const screens = useBreakpoint();

    const slides = chunk(companies || [], screens.md ? imagesPerSlide : 6);

    if (seeMore && slides[slides.length - 1].length === imagesPerSlide) {
        slides[slides.length - 1].pop();
    }

    return (
        <Row>
            <Col span={24}>
                <Carousel
                    autoplaySpeed={7000}
                    autoplay={autoplay}
                    ref={slider}
                    afterChange={(index) => {
                        setIndex(index);
                    }}
                >
                    {slides.map((slide, index) => (
                        <div key={`slide-${index}`}>
                            <Row gutter={[16, 16]} align="middle">
                                {slide.map((company) => {
                                    return (
                                        <Col
                                            xs={12}
                                            md={6}
                                            xl={4}
                                            key={`slide-${index}-company-${company.seoSlug}`}
                                        >
                                            <Link href={`/katalog-firm/${company.seoSlug}`}>
                                                <Tooltip title={company.name}>
                                                    <CompanyImage company={company} />
                                                </Tooltip>
                                            </Link>
                                        </Col>
                                    );
                                })}
                                {seeMore && index === slides.length - 1 && (
                                    <Col xs={12} md={6} xl={4}>
                                        <SeeMoreCard url="/katalog-firm" />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    ))}
                </Carousel>
            </Col>
            <Col span={24}>
                <Col span={24} style={{ padding: '15px 0 0 0' }}>
                    <Row key={`pagination`}>
                        <Col span={24}>
                            <CarouselPagination
                                goNext={goNext}
                                goPrevious={goPrevious}
                                currentSlide={index}
                                slides={slides}
                            />
                        </Col>
                    </Row>
                </Col>
            </Col>
        </Row>
    );
};

CompanyCarousel.whyDidYouRender = false;

export default CompanyCarousel;
