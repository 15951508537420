import React, { useEffect } from 'react';
import { Row, Col, Divider, Modal } from 'antd';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import getConfig from 'next/config';
import { LoadingOutlined } from '@ant-design/icons';
import dynamic from 'next/dynamic';
import OpeningSection from 'components/OpeningSection';
import { EventsSection } from 'components/EventsSection';
import FirstArticlePromotedSection from 'components/FirstArticlePromotedSection';
import Newsletter from 'containers/Newsletter';
import CardGrid from 'components/CardGrid';
import Content from 'layout/Basic/Content';
import Sider from 'layout/Basic/Sider';
import GalleryMainHitbox from 'components/GalleryHitbox/MainHitbox';
import VideoMainHitbox from 'components/VideoHitbox/MainHitbox';
import VideosCarousel from 'components/VideoesCarousel ';
import SectionTitle from 'components/SectionTitle';
import OurIssueSection from 'components/OurIssueSection';
import AppSmallLogotypeIcon from 'components/Icons/AppSmallLogotype';
import Section from 'components/Section';
import CompanyCarousel from 'components/CompanyCarousel';
import { TagsSection } from 'components/TagsSection';

const PromotedSection = dynamic(() => import('components/Promoted'), { ssr: true });
const SkyscraperMobile = dynamic(() => import('components/Ads/SkyscraperMobile'), {
    ssr: false
});
const Rectangle = dynamic(() => import('components/Ads/Rectangle'), {
    ssr: false
});
const HalfPage = dynamic(() => import('components/Ads/HalfPage'), {
    ssr: false
});
const ExpandNavibox = dynamic(() => import('components/Ads/ExpandNavibox'), {
    ssr: false
});
const Wideboard = dynamic(() => import('components/Ads/Wideboard'), {
    ssr: false
});

const H1 = styled.h1`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: Merriweather;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    margin-bottom: 0;
`;

export const Text = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
    @media (max-width: 992px) {
        display: none;
    }
    a {
        :hover {
        }
        color: ${(props) => props.color || props.theme.colors.greyishBrownTwo};
    }
`;

const Icon = styled(AppSmallLogotypeIcon)`
    vertical-align: -0.25em;
    font-size: 30px;
`;

export interface Props {
    ads: PageAds;
    hitboxItems: {
        articles: Article[];
        contests: Contest[];
    };
    news: Article[];
    recentArticles: Article[];
    currentCampaign: Campaign;
    mostPopularArticles: Article[];
    paidArticles: {
        results: Article[];
    };
    articlesMarket: Article[];
    expertZone: Article[];
    motorcycles: Article[];
    forMotorcycle: Article[];
    tags: Tag[];
    galleries: Gallery[];
    videos: Video[];
    companies: Company[];
    whitePapers: WhitePaper[];
    eventsPatronate: Occurrence[];
    events: Occurrence[];
    latestIssue: Issue;
}

export const HomePage = ({
    ads,
    hitboxItems,
    news,
    recentArticles,
    currentCampaign,
    mostPopularArticles,
    paidArticles,
    tags,
    galleries,
    videos,
    companies,
    whitePapers,
    eventsPatronate,
    events,
    articlesMarket,
    expertZone,
    motorcycles,
    forMotorcycle,
    latestIssue
}: Props): JSX.Element => {
    const router = useRouter();
    const { rectangle } = ads;
    useEffect(() => {
        if (typeof router.query.newsletter_confirm !== 'undefined') {
            const modal = Modal.info({
                title: 'Aktywacja subskrypcji',
                content: (
                    <div>
                        <p>
                            <LoadingOutlined spin /> <span>Trwa aktywowanie subskrypcji...</span>
                        </p>
                    </div>
                )
            });

            const { apiUrl } = getConfig().publicRuntimeConfig;
            fetch(`${apiUrl}/public/api/newsletter/${router.query.token}`, {
                method: 'get',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) throw response.json();

                    return response.json();
                })
                .then(() => {
                    modal.update({
                        type: 'success',
                        content: 'Proces aktywacji subskrypcji, został zakończony pomyślnie.'
                    });
                })
                .catch((response) => {
                    response.then((info) => {
                        if (info.code === 1) {
                            modal.update({
                                type: 'error',
                                content: (
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: info.message }}></p>
                                    </div>
                                )
                            });
                        } else {
                            modal.update({
                                type: 'error',
                                content: (
                                    <div>
                                        <p>
                                            Wystąpił nieoczekiwany bład, Twój token wygasł, lub jest
                                            niepoprawny. Spróbuj ponownie, lub skontaktuj się z
                                            administratorem.
                                        </p>
                                    </div>
                                )
                            });
                        }
                    });
                });
        }
        if (typeof router.query['dynamic-form_confirm'] !== 'undefined') {
            const modal = Modal.info({
                title: 'Potwierdzanie...',
                content: (
                    <div>
                        <p>
                            <LoadingOutlined spin /> <span>Trwa potwierdzanie...</span>
                        </p>
                    </div>
                )
            });

            const { apiUrl } = getConfig().publicRuntimeConfig;
            fetch(`${apiUrl}/public/api/dynamic-form/${router.query.token}/confirm`, {
                method: 'get',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) throw response.json();

                    return response.json();
                })
                .then(() => {
                    modal.update({
                        type: 'success',
                        content:
                            'Dziękujemy za wypełnienie formularza, twoje dane zostały zatwierdzone.'
                    });
                })
                .catch((response) => {
                    response.then((info) => {
                        if (info.code === 1) {
                            modal.update({
                                type: 'error',
                                content: (
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: info.message }}></p>
                                    </div>
                                )
                            });
                        } else {
                            modal.update({
                                type: 'error',
                                content: (
                                    <div>
                                        <p>
                                            Wystąpił nieoczekiwany bład, Twój token wygasł, lub jest
                                            niepoprawny. Spróbuj ponownie, lub skontaktuj się z
                                            administratorem.
                                        </p>
                                    </div>
                                )
                            });
                        }
                    });
                });
        }
    }, []);

    return (
        <>
            <OpeningSection $items={hitboxItems} />
            <Section>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }}>
                    <SkyscraperMobile id={ads.skyscraper[0]} margin="35px auto 0 auto" />
                </Col>
                <Col xs={{ span: 24, order: 2 }} md={{ span: 24, order: 3 }}>
                    <CardGrid
                        type="article"
                        title="Wiadomości"
                        url="/wiadomosci"
                        items={news}
                        noTeaser
                        noImages
                    />
                </Col>
                <Col xs={{ span: 24, order: 3 }} md={{ span: 12, order: 2 }}>
                    <SkyscraperMobile id={ads.skyscraper[1]} margin="35px auto 0 auto" />
                </Col>
            </Section>
            <Divider />
            <Section gutter={16}>
                <Content>
                    <Row>
                        <Col span={24}>
                            <FirstArticlePromotedSection
                                title="Ostatnio dodane"
                                items={recentArticles}
                            />
                        </Col>
                    </Row>
                </Content>
                <Sider>
                    <Row>
                        <Col span={24}>
                            <Rectangle id={ads.rectangle[0]} margin="15px auto 15px auto" />
                        </Col>
                        <Col span={24}>
                            <HalfPage id={ads.halfpage[0]} margin="15px auto 15px auto" />
                        </Col>
                    </Row>
                </Sider>
            </Section>
            <Section gutter={[16, 32]} justify="center">
                <Col span={24}>
                    <Divider />
                </Col>
                <Col xs={24} md={20}>
                    <Newsletter />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
            </Section>
            {(ads.navibox[0] || ads.rectangle[1]) && (
                <>
                    <Section gutter={[16, 32]}>
                        <Content>
                            <ExpandNavibox id={ads.navibox[0]} margin="0 auto 24px auto" />
                        </Content>
                        <Sider>
                            <Rectangle id={ads.rectangle[1]} margin="0 auto 24px auto" />
                        </Sider>
                    </Section>
                    <Divider />
                </>
            )}
            {currentCampaign && currentCampaign.campaign && (
                <Row>
                    <Col span={24}>
                        <PromotedSection campaign={currentCampaign} />
                    </Col>
                </Row>
            )}
            <Section>
                <Col>
                    <CardGrid
                        type="article"
                        title="Najchętniej czytane"
                        items={mostPopularArticles}
                        noTeaser
                    />
                </Col>
            </Section>
            <Divider />
            {paidArticles && paidArticles.results.length > 0 && (
                <>
                    <Section>
                        <Content>
                            <CardGrid
                                type="article"
                                title={
                                    <>
                                        <Icon /> Premium
                                    </>
                                }
                                url="/premium"
                                items={paidArticles.results}
                                maxItems={6}
                                maxItemsInRow={3}
                                noTeaser
                                showShowMore={false}
                            />
                        </Content>
                        <Sider>
                            <Row>
                                <Col span={24}>
                                    <Rectangle id={ads.rectangle[2]} margin="15px auto 15px auto" />
                                </Col>
                                <Col span={24}>
                                    <HalfPage id={ads.halfpage[1]} margin="15px auto 15px auto" />
                                </Col>
                            </Row>
                        </Sider>
                    </Section>
                    <Divider />
                </>
            )}
            <Section>
                <Col>
                    <CardGrid
                        type="article"
                        title="Rynek"
                        url="/rynek"
                        items={articlesMarket}
                        noTeaser
                    />
                </Col>
            </Section>
            <Divider />
            <Section>
                <Content>
                    {galleries && (
                        <>
                            <Row>
                                <SectionTitle title="Galeria" url="/galerie" />
                                <Col span={24}>
                                    <GalleryMainHitbox gallery={galleries[0]} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ paddingTop: 45 }}>
                                    <CardGrid
                                        items={galleries.slice(1, -1)}
                                        type="gallery"
                                        url="/galeria"
                                        maxItems={4}
                                        maxItemsInRowMobile={2}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Section $marginTop={21}>
                        <ExpandNavibox id={ads.navibox[1]} margin="0 auto 24px auto" />
                    </Section>
                </Content>
                <Sider>
                    <Row>
                        <Col span={24}>
                            <Rectangle id={ads.rectangle[3]} margin="35px auto 0 auto" />
                        </Col>
                        <Col span={24}>
                            <HalfPage id={ads.halfpage[2]} margin="35px auto 0 auto" />
                        </Col>
                        <Col span={24}>
                            <Rectangle id={ads.rectangle[4]} margin="35px auto 0 auto" />
                        </Col>
                    </Row>
                </Sider>
            </Section>
            <Row>
                <Col>
                    <CardGrid
                        type="article"
                        title="Strefa eksperta"
                        url="/strefa-eksperta"
                        items={expertZone}
                        noTeaser
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CardGrid
                        type="article"
                        title="Motocykle"
                        url="/motocykle"
                        items={motorcycles}
                        noTeaser
                    />
                </Col>
            </Row>

            <Row style={{ margin: '24px 0 48px' }} justify="center">
                <Col>
                    <Wideboard id={ads.wideboardBottom[0]} />
                </Col>
            </Row>
            <Divider />
            <TagsSection tags={tags} />
            <Row>
                <Col span={24}>
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Content>
                    {companies.length > 0 && (
                        <>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <SectionTitle
                                    title="Dostawcy treści"
                                    url="/katalog-firm"
                                    subTitle="Partnerzy portalu"
                                />
                            </Col>
                            <Col span={24}>
                                <CompanyCarousel companies={companies} />
                            </Col>
                        </>
                    )}
                </Content>
                <Sider>
                    <Row>
                        <Col span={24}>
                            <Rectangle id={rectangle[5]} margin="100px 0 0 0" />
                        </Col>
                    </Row>
                </Sider>
            </Row>
            <Row>
                <Col>
                    <CardGrid
                        type="article"
                        title="Do motocykla"
                        url="/do-motocykla"
                        items={forMotorcycle}
                        noTeaser
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row gutter={24} style={{ marginTop: 24 }}>
                        <Content>
                            {videos && (
                                <Row>
                                    <SectionTitle title="Wideo" url="/wideo" />
                                    <Col span={24}>
                                        <VideoMainHitbox video={videos[0]} />
                                    </Col>
                                </Row>
                            )}
                        </Content>
                        <Sider style={{ paddingTop: 45 }}>
                            <Row>
                                <Col span={24}>
                                    <HalfPage id={ads.halfpage[3]} />
                                </Col>
                            </Row>
                        </Sider>
                    </Row>
                    <Row gutter={24}>
                        <Content>
                            {videos && (
                                <Row>
                                    <Col span={24} style={{ paddingTop: 45 }}>
                                        <VideosCarousel videos={videos.slice(1, 8)} seeMore />
                                    </Col>
                                </Row>
                            )}
                        </Content>
                        <Sider>
                            <Row>
                                <Col span={24}>
                                    <Rectangle id={rectangle[6]} margin="45px 0 0 0" />
                                </Col>
                            </Row>
                        </Sider>
                    </Row>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 16 }}>
                <Content>
                    {whitePapers && (
                        <CardGrid
                            type="white-paper"
                            title="White Papers"
                            url="/white-papers"
                            items={whitePapers}
                        />
                    )}
                </Content>
                <Sider>
                    <HalfPage id={ads.halfpage[4]} />
                </Sider>
            </Row>
            <Divider />
            <Row gutter={16}>
                <Content>
                    <EventsSection events={events} eventsPatronate={eventsPatronate} />
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <ExpandNavibox id={ads.navibox[2]} margin="0 auto 24px auto" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
                <Sider style={{ padding: 10 }}>
                    <Row>
                        <OurIssueSection issue={latestIssue} showSubscriptionHref={false} />
                    </Row>
                </Sider>
            </Row>
        </>
    );
};

export default HomePage;
