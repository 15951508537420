import React from 'react';
import Image from 'components/Image';

interface Props {
    company: Company;
    size?: number;
}

const CompanyImage = ({ company, size = 124 }: Props): JSX.Element => (
    <Image type="logo" src={company.logo} alt={company.name} width={size} height={size} />
);

export default CompanyImage;
