import React, { useState, useRef } from 'react';
import { Row, Col, Carousel, Grid } from 'antd';
import VideoCard from 'components/VideoCard';
import CarouselPagination from 'components/CarouselPagination';
import SeeMoreCard from 'components/SeeMoreCard';
import chunk from 'lib/chunkArray';

const GRID_COLS = 24;
const { useBreakpoint } = Grid;

interface Props {
    videos: Video[];
    itemsPerRow?: number;
    seeMore?: boolean;
}

export const VideosCarousel = ({
    videos,
    itemsPerRow = 4,
    seeMore = false
}: Props): React.ReactElement => {
    const [index, setIndex] = useState(0);
    const slider = useRef(null);

    const goNext = () => {
        slider.current.next();
    };

    const goPrevious = () => {
        slider.current.prev();
    };

    const screens = useBreakpoint();

    const slides = chunk(videos, screens.md ? itemsPerRow : 2);

    if (seeMore && slides[slides.length - 1].length === itemsPerRow) {
        slides[slides.length - 1].pop();
    }

    return (
        <Row>
            <Col span={24}>
                <Carousel
                    ref={slider}
                    dots={false}
                    afterChange={(index) => {
                        setIndex(index);
                    }}
                >
                    {slides.map((slide, i) => {
                        return (
                            <div key={`video-carousel-slide-${i}`}>
                                <Row gutter={24}>
                                    {slide.map((item: Video) => (
                                        <Col key={item.title} md={GRID_COLS / itemsPerRow} xs={12}>
                                            <VideoCard video={item} />
                                        </Col>
                                    ))}
                                    {seeMore && slides.length === i + 1 && (
                                        <Col xs={12} md={GRID_COLS / itemsPerRow}>
                                            <SeeMoreCard url="/wideo" />
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        );
                    })}
                </Carousel>
            </Col>
            <Col span={24} style={{ padding: '15px 0 15px 0' }}>
                <Row key={`pagination`}>
                    <Col span={24}>
                        <CarouselPagination
                            goNext={goNext}
                            goPrevious={goPrevious}
                            currentSlide={index}
                            slides={slides}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

VideosCarousel.whyDidYouRender = false;

export default VideosCarousel;
