import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

const A = styled.a`
    color: ${(props) => props.color || props.theme.colors.primary};
    :hover {
        text-decoration: none;
        color: ${(props) => props.color || props.theme.colors.primary};
    }
`;

const H1 = styled.h1`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: Merriweather;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 27px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
`;

const SubSectionTitle = styled.p`
    font-family: Fira Sans;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0;
    padding-top: 10px;
    color: ${(props) => props.theme.colors.greyishBrownTwo};
`;

const Icon = styled(RightOutlined).attrs({ size: 14 })`
    padding-left: 20px;
`;

const Wrapper = styled.div`
    margin-bottom: 25px;
`;

interface Props {
    title: string | JSX.Element;
    subTitle?: string;
    url?: string;
    id?: string;
}

const SectionTitle = ({ title, url = undefined, subTitle = undefined, id = undefined }: Props) => {
    if (!url) {
        return (
            <Wrapper>
                <H1>{title}</H1>
                {subTitle && <SubSectionTitle>{subTitle}</SubSectionTitle>}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <H1 id={id}>
                <Link href={url} passHref>
                    <A>
                        {title}
                        <Icon />
                    </A>
                </Link>
            </H1>
            {subTitle && <SubSectionTitle>{subTitle}</SubSectionTitle>}
        </Wrapper>
    );
};

export default SectionTitle;
